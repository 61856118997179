import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Icon } from 'react-icons-kit';
import { ic_keyboard_arrow_down } from 'react-icons-kit/md/ic_keyboard_arrow_down';
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { MdHelpOutline } from 'react-icons/md';
import { Button } from 'reactstrap';
import classnames from 'classnames';

const FeatureTable = () => {
  const [isOpen, setIsOpen] = useState(true);
  const [subscriptionFeature, setSubscriptionFeature] = useState([]);
  const [subscriptionfeaturespertypes, setSubscriptionfeaturespertypes] = useState([]);
  const [subscriptionType, setSubscriptionType] = useState([]);
  const [activeView, setActiveView] = useState("entreprise"); 

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_APP_DOMAIN}/api/subscriptionFeature/getByCategories`);
        setSubscriptionFeature(response.data);
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_APP_DOMAIN}/api/subscriptionType/getAll`);
        setSubscriptionType(response.data);
        console.log("subscription:", response.data);
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_APP_DOMAIN}/api/subscriptionfeaturespertypes/findAllWithTypes`);
        setSubscriptionfeaturespertypes(response.data);
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, []);

  const filteredSubscriptionType = subscriptionType.filter(subscription => {
    if (activeView === "solo") {
      return subscription.Type.toLowerCase().includes("solo") || subscription.Type.toLowerCase() === "free" || subscription.Type.toLowerCase() === "pay as you go";
    } else if (activeView === "entreprise") {
      return subscription.Type.toLowerCase().includes("entreprise") || subscription.Type.toLowerCase() === "free" || subscription.Type.toLowerCase() === "pay as you go";
    }
    return false;
  });

  return (
    <div className="feature-section">
<div className="text-center mt-3">
  <label className="lunar-solar-switch1">
    <input
      type="checkbox"
      className="visually-hidden"
      checked={activeView === "entreprise"}
      onChange={() => setActiveView(activeView === "solo" ? "entreprise" : "solo")}
    />
    <span className="lunar-solar-label1">
    <span className={`switch-option1 ${activeView === "entreprise" ? "active" : ""}`}>entreprise</span>
      <span className={`switch-option1 ${activeView === "solo" ? "active" : ""}`}>solo</span>
     
    </span>
    <span className="toggle-slider1"></span>
  </label>
</div>



      <div className="toggle-features" onClick={() => setIsOpen(!isOpen)}>
        <Icon icon={ic_keyboard_arrow_down} /> Features
      </div>
      <br />

      {isOpen && (
        <div className="feature-table-wrapper">
          <table className="feature-table">
            <thead>
              <tr>
                <th className="feature-category">Feature</th>
                {filteredSubscriptionType.map((subscription) => (
                  <th className="plan-header" key={subscription.id}>
                        {subscription.Type === "Pay as you go" ? "Customized offer" : subscription.Type}
                    <br />
                    <span className="plan-availability">Available</span>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {Object.keys(subscriptionFeature).map((category) => (
                <React.Fragment key={category}>
                  <tr className="category-row">
                    <td colSpan={filteredSubscriptionType.length + 1}><b>{category}</b></td>
                  </tr>
                  {subscriptionFeature[category].map((feature) => (
                    <tr key={feature.name}>
                      <td className="feature-name">{feature.name}
                        <OverlayTrigger placement="top" overlay={<Tooltip>{feature.description}</Tooltip>}>
                          <span style={{ cursor: 'pointer' }}>
                            <MdHelpOutline />
                          </span>
                        </OverlayTrigger>
                      </td>
                      {filteredSubscriptionType.map((subscription) => (
                        <td key={subscription.id}>
                          {subscriptionfeaturespertypes
                            .filter((subType) => subType.feature_id === feature.id && subType.type_id === subscription.id && subType.feature_available === 1)
                            .map((subType) => (
                              <ul key={subType.feature_id}>
                                <ol>
                                  <i className="mdi mdi-check-bold bg-success-subtle text-success me-2"></i>
                                  {subType.feature_quota_max}
                                </ol>
                              </ul>
                            ))}
                        </td>
                      ))}
                    </tr>
                  ))}
                </React.Fragment>
              ))}
            </tbody>
          </table>
        </div>
      )}

      <style jsx>{`
        .toggle-features {
          background: #3498db;
          padding: 10px;
          cursor: pointer;
          width: 100%;
          text-align: center;
          font-weight: bold;
          color: #fff;
          border-radius: 5px;
        }

        .lunarSwitcher {
          margin: 10px;
        }

        .lunarSwitcher.active {
          background-color: #02af74;
          color: #fff;
        }

        .feature-table-wrapper {
          background: #f9f9f9;
          padding: 20px;
          margin-top: 20px;
        }

        .feature-table {
          width: 100%;
          border-collapse: collapse;
          box-shadow: 0 0 15px #3498db;
        }

        .feature-table th,
        .feature-table td {
          padding: 15px;
          text-align: left;
          border-bottom: 1px solid #3498db;
        }

        .feature-table th {
          background: #85c7f2;
          color: white;
        }

        .plan-header {
          text-align: center;
        }

        .category-row {
          background: #f1f3f9;
        }
      `}</style>
    </div>
  );
};

export default FeatureTable;
