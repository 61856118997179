const translations = {
  en: {
    language: 'English',
    menu: {
      pageTitle: 'Your professional future starts here: Discover a smarter way to recruit or land your dream job.',
      liveJobs:"live jobs",
      wehave:"We offer thousands of live job opportunities",
      pageDescr1:"For employers, streamline your recruitment processes with end-to-end management: personality, psychometric, and technical assessments, plus AI-driven insights for precise role matching.",
      pageDescr2:"For candidates, find opportunities that align perfectly with your skills and aspirations. Assess your abilities, showcase your strengths, and unlock tailored career opportunities.",
      livejobs2:"We have plenty of live Jobs",
      RequestDemo:"Request Demo",
      BrowserFeatures:"Browser Features",
      BrowserFeaturesDesc1:"Experience our website and more to make your browsing faster, safer and more tailored to your .",
      BrowserFeaturesDesc2:"These features collectively enhance browsing efficiency, personalization and security ",
      NewRandomJobs:"New & Random Jobs",
      NewRandomJobsDesc1:"Unlock your potential : the right job is just a click away",
      NewRandomJobsDesc2:"Effortless connect with the best freelancers for your needs ",
      forCandidate:"For Candidate",
      forRecruiter:"For Recruiter",
      forCompany:"For Company",
      candiadtesGuide:"How It Works for Candidates",
      candidatesGuideDesc:"Get started with your application",
      candidatestep1Title:"Create an account ",
      candidatestep1:"Sign up by providing your basic details and creating a profile. This helps us underestand your skills, experience and career goals",
      candidatestep2Title:"Complete your profile",
      candidatestep2:"Fill out your profile with information about your qualifications, work history and preferences. This ensures we match you with relevant opportunities",
      candidatestep3Title:"Browse opportunities",
      candidatestep3:"Explore available job listing or projects that align with your skills and interests",
      candidatestep4Title:"Submit application",
      candidatestep4:"Apply to the positions or projects that interst you. Tailor your application to highlight how your background fits the role",
      candidatestep5Title:"Get matched",
      candidatestep5:"Our system reviews your profile and applications, then matches you with the most suitable opportunities",
      candidatestep6Title:"Play coding games",
      candidatestep6:"Sharpen your coding skills through fun games.",

      recruiterGuide:"How It Works for Recruiters",
      recruiterGuideDesc:"How the process works for recruiters.",
      recruitertep1Title: "Create an account",
      recruitertep1: "Register by providing your basic information and creating a profile. This allows us to better understand your needs and put you in touch effectively and efficiently",
      recruitertep2Title: "Create a detailed job description",
      recruitertep2: "Ensure that job descriptions are accurate and include key responsibilities, required qualifications and desired skills",
      recruitertep3Title: "Establish assessment criteria",
      recruitertep3: "Develop clear criteria for assessing candidates (technical, psychotechnical and personality) to ensure consistency and fairness in the selection process",
      recruitertep4Title: "Preselect your best candidates in the blink of an eye",
      recruitertep4: "Quickly filter your applications with an optimised AI algorithm that matches the best CVs of candidates for the job and prepares a shortlist for you",
      recruitertep5Title: "Manage all your personality, psycho-technical and coding tests in a single click and send them to candidates",
      recruitertep5: "Candidates shortlisted on the basis of a personalised compatibility score will be able to log on quickly and take the tests requested and personalised for the position requested",
      recruitertep6Title: "Monitor test progress and finalise the recruitment process through an optimised experience",
      recruitertep6: "Test results and summaries are fed back to you in real time, enabling you to decide whether or not a candidate should be considered. This includes personalised summaries provided by the AI of each candidate's strengths and weaknesses",
      recruitertep7Title: "Plan the interview, prepare your questions and take notes in real time",
      recruitertep7: "Hirecue allows you to manage the recruitment process from start to finish by booking interviews and taking notes of everything needed for a successful hire",
      recruitertep8Title:"Company Option",
      recruitertep8:" The company option allows you to manage multiple recruiters simultaneously with a company owner and managers. It's ideal for recruitment agencies, software publishers, and any type of business aiming to enhance their recruitment activities and optimize their human potential through continuous testing and skills tracking.",

      checkOur:"Check our",
      latestJob:"latest job openings now",
      descCheck:"Share your project with us, and we will swiftly connect you with the perfect freelancers",
      HappyCandidates:"Happy Candidates",
      HappyCandidatesDesc:"At Hirecue, we place the well-being and success of our candidates at the heart of our approach. Because a happy candidate is the start of a successful partnership!",
      FastTrack:"Fast track your success and quick career tips",
      FastTrackDesc:"Submit your project details, and we will quickly find the right freelancers for you",
      hirecueFooter:"Find the talent that will make the difference in your company.",
      hirecueFooterTit:"With Hirecue,",
      Pricing:" Pricing",
      AboutUs :"About Us ",
      SignIn:"Sign In",
      SignUp:"Sign Up",
      email:"Email",
      phone:"Phone",
      type:"Type",
      Customdemo:"Custom demo",
      Generaldemo:"General demo",
      description:"Description",
      close:"Close",
      submit:"Submit",
      Jobs:"Jobs and careers",
      ApplyNow:"Apply Now",
      Skills:"Skills",
      Experience:"Experience", 
      Offres:"Job offers",
      viewMore:"View More",
      startFree:"Start for free now",
      inscriptionGratuite:"Sign up for free !",
      general:"general",
      generalQuestion1: "How do I create an account on the platform?",
      generalAnswer1:`1. Click on "Sign up for free !" at the top of the page.
                     2. Fill in your details and confirm.
                     3. You will receive a verification e-mail. Click on the link to activate your account.
                     4. Log in and choose your role (Candidate, Recruiter, Company)..,`,
      generalQuestion2: "What roles are available and what are their benefits?",
      generalAnswer2:`- **Candidate**: Apply for jobs and take tests (Free).
                 - **Recruiter**: Access to job creation and test tools for individual recruiters.
                 - **Company**: Advanced features for businesses, including team management.`,
      generalQuestion3: "How do I choose a role after registering?",
      generalAnswer3:`After checking your e-mail address, log in and choose your role.
               If you choose "Recruiter" or "Company", an administrator will contact you to guide you and discuss subscriptions.`,
               subscription: `Subscription`,
               subscriptionQuestion1: `What subscriptions are available to recruiters and companies?`,
               subscriptionAnswer1:`We offer monthly and annual plans with unlimited testing options, CV analysis, and pre-screening tools.`,
               subscriptionQuestion2: `How does the subscription process work?`,
               subscriptionAnswer2:`1. Choose the "Recruiter" or "Company" role.
                        2. An administrator will contact you to explain the subscription options.
                        3. Make payment by bank transfer.
                        4. Your account will be activated within 24 hours of confirmation of payment`,
               payment: `Payment`,
               paymentQuestion1: `Which payment methods do you accept?`,
               paymentAnswer1:`We accept credit cards, PayPal, as well as payments via Stripe and Payme for annual payments`,
               paymentQuestion2: `Will I receive an invoice after payment?`,
               paymentAnswer2:`Yes, an invoice is automatically generated and available in your dashboard after each payment`,
               paymentQuestion3: `How does payment via Stripe and Payme work?`,
               paymentAnswer3:`We use Stripe and Payme to guarantee secure payments. You can choose one of these payment methods when you subscribe, and the payment process is quick and easy`,
               support: `Support`,
               supportQuestion1: `How do I contact customer support?`,
               supportAnswer1:`You can contact us using the form on the "Contact us" page or send an e-mail to contact@hirecue.com.`,
               supportQuestion2: `Is there any documentation for new users?`,
               supportAnswer2:`Yes, video tutorials and full documentation are available to help you use the platform.`,
               additional:"Additional",
               additionalQuestion1: "What are candidates?",
               additionalAnswer1:`Candidates are individuals who apply for job offers and participate in assessments on the platform.`,
               additionalQuestion2: "How does Hirecue protect my data?",
               additionalAnswer2:`Hirecue uses encryption and security protocols to ensure your data is protected at all stages of use.`,
               additionalQuestion3: "What's the difference between an assessment and a test?",
               additionalAnswer3:`An assessment evaluates the candidate's overall abilities and skills, while a test is a specific evaluation of one particular skill.`,
               additionalQuestion4: "Is Hirecue easy to use?",
               additionalAnswer4:`Yes, Hirecue is designed with a user-friendly interface that makes it easy for both recruiters and candidates to navigate.`,
               additionalQuestion5: "What type of support do you offer?",
               additionalAnswer5:`We offer email support, as well as phone support for premium subscribers. Additionally, we have a comprehensive FAQ and knowledge base.`,
               additionalQuestion6: "I have more questions.",
               additionalAnswer6:`If you have more questions, feel free to reach out to our support team via the 'Contact Us' page or send an email to contact@hirecue.com.`,
              contacterNous:"Contact us" ,
              envoiemail:"Send an e-mail",
              choosePlan:"Choose Your Plan",
              choosePlanDesc:"Choose the plan that fits your needs and start your journey today!",
              monthly:"Monthly",
              annual:"Annual",
              noJobsAvailable: "No jobs available at the moment.",
              errorFetchingJobs: "Error retrieving job offers.",
              loading: "Loading in progress...",
              politiqueCookies: "Cookies policy",
              explanation: "This Cookie Policy explains how we use cookies on our recruitment platform. By using our site, you agree to the use of cookies in accordance with this policy. If you do not agree to the use of cookies, you can disable them or change your settings at any time.",
              whatIsACookie: "1. what is a cookie?",
              cookieDescription: "A cookie is a small data file that is stored on your device (computer, mobile phone, tablet) when you visit a website. Cookies allow us to offer you a personalised experience, track your browsing, and help us analyse how our site is used.",
              whyCookies: "2. why do we use cookies?",
              cookiePurpose: "We use cookies for a variety of reasons, including to enhance your experience on our recruitment platform, personalise content, and analyse interaction with our site. These are the types of cookies we use:",
              necessaryCookies: "Cookies nécessaires",
              necessaryCookiesDescription: "Ces cookies sont essentiels pour le bon fonctionnement de notre site. Ils permettent des fonctions de base telles que la gestion de votre session, l'accès à des zones sécurisées du site, et l'enregistrement de vos préférences de langue. Ces cookies ne peuvent pas être désactivés.",
              preferenceCookies: "Cookies de préférences",
              preferenceCookiesDescription: "Ces cookies mémorisent vos préférences, telles que votre langue ou la région que vous avez choisie, pour personnaliser votre expérience sur notre site.",
              performanceCookies: "Cookies de performance et d'analyse",
              performanceCookiesDescription: "Ces cookies nous permettent d'analyser l'utilisation du site afin de comprendre comment les visiteurs interagissent avec notre plateforme. Cela nous aide à améliorer l'efficacité et la performance de notre site.",
              advertisingCookies: "Advertising cookies",
              advertisingCookiesDescription: "These cookies are used to display relevant advertisements based on your interests. They may also be used to limit the number of times you see an advertisement and to measure the effectiveness of the advertising campaign",
              manageCookies: "3. managing cookies",
              manageCookiesDescription: "You can manage your cookie preferences. You can deactivate cookies at any time by accessing your browser settings. Please note that if you disable cookies, some of the functions on our site may not function properly",
              browserSettings: "Here's how you can manage cookies on the main browsers:",
              googleChrome: "Google Chrome: Go to “Settings” > “Privacy and Security” > “Cookies and other site data”.",
              mozillaFirefox: "Mozilla Firefox: Go to “Options” > “Privacy and Security” > “Cookies and Website Data”.",
              safari: "Safari: Go to “Preferences” > “Privacy” > “Cookies and Website Data”",
              microsoftEdge: "Microsoft Edge: Go to “Settings” > “Privacy, Search and Services” > “Cookies”.",
              cookieConsent: "4. consent to the use of cookies",
              cookieConsentDescription: "By continuing to browse our site, you consent to the use of cookies in accordance with this Cookie Policy. You can accept or refuse the use of cookies when you access our site for the first time, via the cookie consent banner",
              cookiePolicyChanges: "5. changes to the Cookie Policy",
              cookiePolicyChangesDescription: "We reserve the right to change this cookie policy at any time. Any changes will be posted on this page. We encourage you to check this page regularly for any updates",
              contact: "6. Contact",
              contactDescription: "If you have any questions about our Cookie Policy, please do not hesitate to contact us :",
              contactEmail: "By e-mail :",
              contactPhone: "By phone :",
              contactAddress: "By post:",
              lastUpdated: "Last updated: ",
              politiqueConfidentialite: "Privacy Policy",
              engagementConfidentialite: "At HireCue, we are committed to protecting the privacy and security of our users' personal information. This privacy policy explains how we collect, use, and protect your data within the scope of our recruitment platform.",
              collecteInformations: "1. Information Collection",
              collecteDetails: "We collect various types of information about you when using our recruitment platform, including:",
              informationInscription: "Information you provide during registration as a company, recruiter, or candidate.",
              informationCV: "Information contained in your CV, including your professional experience, skills, and qualifications.",
              informationTests: "Data related to your interactions with job offers, including psychometric tests and assessments (technical and multiple-choice).",
              donneesNavigation: "Data related to your browsing and usage of the platform, such as pages visited, searches made, etc.",
              utilisationInformations: "2. Use of Information",
              utilisationDetails: "The collected information is used for the following purposes:",
              gestionComptes: "Managing user accounts (candidates, recruiters, companies).",
              associationCompetences: "Matching candidate skills with job offers by analyzing CVs.",
              facilitationRecrutement: "Facilitating the recruitment process, including assigning psychometric tests, evaluations, and sending email invitations to selected candidates.",
              personnalisationExperience: "Personalizing your experience on the platform based on your preferences and past actions.",
              ameliorationServices: "Improving our services and platform performance by analyzing browsing and usage data.",
              protectionInformations: "3. Information Protection",
              protectionDetails: "We implement technical and organizational measures to protect your personal data from unauthorized access, alteration, disclosure, or destruction. However, no data transmission method on the Internet is completely secure, and we cannot guarantee the absolute security of the transmitted information.",
              partageInformations: "4. Sharing of Information",
              partageDetails: "We share your personal information only with the following parties:",
              recruteursPartage: "Recruiters and companies registered on our platform who need your information to assess your application.",
              prestatairesServices: "Third-party service providers who help us operate the platform (e.g., data storage services, messaging services, analytics tools).",
              autoritesCompetentes: "Competent authorities in case of legal obligations or legitimate requests.",
              droitsUtilisateur: "5. Your Rights",
              droitsDetails: "In accordance with applicable data protection laws, you have several rights regarding your personal data, including:",
              droitAcces: "The right to access your personal data.",
              droitRectification: "The right to correct or update your information.",
              droitSuppression: "The right to delete your information or request limitations on its processing.",
              droitOpposition: "The right to object to the processing of your data in certain situations.",
              contactExerciceDroits: "To exercise these rights, please contact us at the following address: contact@hirecue.com.",
              cookiesSuivi: "6. Cookies and Tracking",
              cookiesSuiviDetails: "We use cookies to enhance your experience on our platform. These cookies help us analyze site usage and personalize content. For more information on managing cookies, please refer to our",
              modificationsPolitique: "7. Changes to the Privacy Policy",
              modificationsDetails: "We reserve the right to modify this privacy policy at any time. Any changes will be posted on this page with a new revision date. We encourage you to regularly review this policy to stay informed about updates.",
              contact: "8. Contact",
              contactDetails: "If you have any questions or concerns regarding this privacy policy, please feel free to contact us at:",
            company:"Company",
            droitreservee:"All rights reserved",
            getTouchdesc:"Start working with HireCue that can provide everything you need to generate awareness, drive traffic, connect.",
            getTouch:"Get in touch",
            NousTitle:"Redefining Recruitment and Job Search",
            NousDesc:"HireCue is an innovative startup committed to transforming how businesses hire and how talents find their dream roles. Our mission is to bridge the gap between employers and candidates with intelligent, efficient, and user-centric tools.",
            organisationAndServices: "Organisation and Services",
  
            solRecTitle: "Solutions for Recruiters",
            solRecDesc: "We offer a comprehensive platform that allows companies to manage the entire recruitment process, from pre-screening to hiring.",
            solRec1: "Personality, Psychotechnical, and Technical Tests",
            solRec1Desc: "Assess candidates with various tests tailored to each role.",
            solRec2: "Automated Summary and Recommendations",
            solRec2Desc: "With advanced AI, we ensure the best match for open positions.",
            solRec3: "Centralized Application Management",
            solRec3Desc: "An intuitive dashboard to track and optimize every step of the recruitment process.",
            
            solCanTitle: "Solutions for Candidates",
            solCanDesc: "Our app helps talents find opportunities perfectly suited to their skills and ambitions.",
            solCan1: "Personalized Matching",
            solCan1Desc: "Opportunities matching your skills and specific expectations.",
            solCan2: "Skills Assessment and Preparation Tools",
            solCan2Desc: "Test and develop your skills to succeed in your field.",
            solCan3: "Optimized CV Creation",
            solCan3Desc: "A modern and attractive CV generator to highlight your profile.",
            
            ProPhare: "Our Key Products",
            HireCueMatch: "Our AI engine for precise and effective matching between candidates and companies.",
            HireCueTests: "A library of specialized tests to assess candidates' abilities.",
            HireCueDashboard: "An intuitive dashboard to track and manage every step of the recruitment process.",
            HireCueBoost: "A candidate support service to maximize their chances of success.",
            serviceFooter:"At HireCue, we believe in smarter, faster and more human recruitment.",
            Country:"Country",
            Description:"Description",
            OfferedSalary:"Offered Salary",
            JobType:"Job Type",
            promotion40:"off until the end of the year for the first 100 customers!",
            priceAfterPromotion: "Price after 40% discount",
            liencopie: "Link copied to clipboard: ",
            joinWhatsAppGroup: "Join WhatsApp Group",
            }
    }
,
  fr: {
  language: 'Français',
  menu: {
    pageTitle: "Votre avenir professionnel commence ici : Explorez une nouvelle façon de recruter ou de trouver l'emploi idéal.",
    liveJobs : " emplois en direct ",
      wehave : " Nous proposons des milliers d'offres d'emploi en direct ",
      pageDescr1 : " Pour les entreprises, optimisez vos processus de recrutement grâce à une gestion complète : tests de personnalité, psychotechniques et techniques, et une synthèse intelligente grâce à l'IA pour un matching précis avec le poste.",
      pageDescr2 : "Pour les candidats, trouvez les missions qui correspondent parfaitement à vos attentes et compétences. Évaluez vos aptitudes, mettez en avant vos points forts, et accédez à des opportunités sur mesure",
      livejobs2 : " Nous avons de nombreux emplois en direct ",
      RequestDemo : " Demander une démonstration ",
      BrowserFeatures : "Fonctionnalités du navigateur ",
      BrowserFeaturesDesc1 : "Découvrez notre site Web et plus encore pour rendre votre navigation plus rapide, plus sûre et mieux adaptée à vos besoins ",
      BrowserFeaturesDesc2 : "Ces fonctionnalités améliorent collectivement l'efficacité, la personnalisation et la sécurité de la navigation ",
      NewRandomJobs : "Emplois nouveaux et aléatoires ",
      NewRandomJobsDesc1 : "Libérez votre potentiel : le bon emploi est à portée de clic ",
      NewRandomJobsDesc2 : "Connectez-vous sans effort avec les meilleurs freelances pour vos besoins ",
      forCandidate : "Pour le candidat ",
      forRecruiter : "Pour le recruteur ",
      forCompany:"Pour l'entreprise",
      candiadtesGuide : "Comment ça marche pour les candidats ",
      candidatesGuideDesc : "Commencez votre candidature ",
      candidatestep1Title : "Créez un compte ",
      candidatestep1 : "Inscrivez-vous en fournissant vos informations de base et en créant un profil. Cela nous aide à comprendre vos compétences, votre expérience et vos objectifs de carrière",
      candidatestep2Title : "Complétez votre profil ",
      candidatestep2 : "Complétez votre profil avec une experience d'onboarding optimisée des informations sur vos qualifications, votre expérience professionnelle et vos préférences.Notre parcours d'onboarding assisté par l'IA permet d'extraire et enrichir rapidement et efficacement vos données à partir de votre CV. Cela nous permet de vous proposer des opportunités pertinentes ",
      candidatestep3Title : "Parcourir les opportunités ",
      candidatestep3 : "Explorez les offres d'emploi ou les projets qui correspondent à vos compétences et à vos intérêts ",
      candidatestep4Title : "Soumettre une candidature ",
      candidatestep4 : "Postulez aux postes ou aux projets qui vous intéressent. Adaptez votre candidature pour mettre en évidence la manière dont votre expérience correspond au poste ",
      candidatestep5Title : "Get matched ",
      candidatestep5 : "Notre système examine votre profil et vos candidatures, puis vous met en relation avec les opportunités les plus appropriées ",
      candidatestep6Title : "Mettez à jour vos compétences et restez dans le top 1% du marché ",
      candidatestep6: "Evaluez en continue vos compétences technique via des coding games, des quizs techniques (QCMs)Hirecue vous permet également de mieux comprendre votre personalité et vos aptitudes professionnelles via des tests psychotechniques",
      recruiterGuide : "Comment ça marche pour les recruteurs ",
      recruiterGuideDesc : "Comment le processus fonctionne pour les recruteurs ",
      recruitertep1Title : "Créer un compte ",
      recruitertep1 : "Inscrivez-vous en fournissant vos informations de base et en créant un profil. Cela nous permet de mieux comprendre vos besoins et de vous mettre en relation de manière efficace et efficiente.",
      recruitertep2Title : " Créer une description de poste détaillée ",
      recruitertep2 : " Veiller à ce que les descriptions d'emploi soient précises et comprennent les principales responsabilités, les qualifications requises et les compétences souhaitées ",
      recruitertep3Title : " Établir des critères d'évaluation ",
      recruitertep3 : " Élaborer des critères clairs pour l'évaluation des candidats (techniques, psychotechniques et de personnalité) afin de garantir la cohérence et l'équité du processus de sélection ",
      recruitertep4Title : "Préselectionnez en un clin d'oeil vos meilleurs candidats ",
      recruitertep4 : " Filtrer rapidement vos candidatures avec un algorithme IA optimisé qui fait le matching avec les meilleurs CV de candidats pour le poste et vous prépare la shortlist",
      recruitertep5Title : "Gérer en un seul clic tous vos tests de personnalité,  psycho-technique et de coding et les envoyer aux candidats",
      recruitertep5 : "Les candidats présélectionnés sur la base d'un score de compatibilité personnalisé vont pouvoir se connecter  rapidement et passer leurs tests demandés et personnalisés pour le poste demandé.",
      recruitertep6Title : " Surveillez la progression de passage des tests et finaliser le process de recrutement via une expérience optimisée",
      recruitertep6: "Les résultats et synthèse de passage de tests vous remontent en temps réel et vos permettent de décider de la suite favorable ou défavorable pour les candidats. Ceci inclut des synthèses personnalisées  fournies par l'IA concernant les forces et les faiblesses de chaque candidat.",
      recruitertep7Title : "Planifier l'entretien , préparer vos questions et prenez des notes en temps réel ",
      recruitertep7 : " Hirecue vous permet  de gérer de bout en bout le process de recrutement en réservant des entretiens et en notant tout ce qui est nécessaire pour la réussite de l'embauche",
      recruitertep8Title:"Option entreprise",
      recruitertep8:"Le choix entreprise vous permet de gérer plusieurs recruteurs à la fois avec un company owner et des managers . C'est plus adapté aux ESNs de recrutement , aux éditeurs logiciels et à tout type d'entreprise souhaitant émanciper leur activités de recrutement et maîtriser leurs potentiel humains via des tests en continu et un suivi de la montée en compétences.",
      checkOur : " Vérifier notre ",
      latestJob : " Dernières offres d'emploi en cours ",
      descCheck : " Partagez votre projet avec nous, et nous vous mettrons rapidement en contact avec les freelances parfaits ",
      HappyCandidates : " Candidats heureux ",
      HappyCandidatesDesc : " Chez Hirecue, nous mettons le bien-être et le succès des candidats au cœur de notre démarche. Parce qu’un candidat heureux, c’est le début d’une collaboration réussie !",
      FastTrack : " Accélérez votre réussite et obtenez des conseils rapides sur votre carrière ",
      FastTrackDesc : " Soumettez-nous les détails de votre projet et nous trouverons rapidement les freelances qu'il vous faut ",
      hirecueFooter : "  Trouvez les talents qui feront la différence dans votre entreprise.",
      hirecueFooterTit:"Avec Hirecue,",
      Pricing:" Tarification ",
      AboutUs :" A propos de nous ",
      SignIn:"Se connecter",
      SignUp:"S'inscrire",
      email : " Email ",
      phone : " Téléphone ",
      type : " Type ",
      Customdemo : " Démonstration personnalisée ",
      Generaldemo : " Démonstration générale ",
      description : " Description ",
      close : " Fermer ",
      submit : " Envoyer ",
      Jobs:"Emplois et carrières",
      ApplyNow : " Postuler maintenant ",
      Skills : " Compétences ",
      Experience : " Expérience ",
      Offres:"Offres d'emploi",
      viewMore:"Voir plus",
      startFree:"Démarrer gratuitement maintenant",
      inscriptionGratuite:"S'inscrire gratuitement !",
      general :` Général `,
      generalQuestion1 :` Comment créer un compte sur la plateforme ? `,
      generalAnswer1:`1. Cliquez sur "Register " en haut de la page.
                     2. Remplissez vos coordonnées et confirmez.
                     3. Vous recevrez un e-mail de vérification. Cliquez sur le lien pour activer votre compte.
                     4. Connectez-vous et choisissez votre rôle (Candidat, Recruteur, Entreprise)...,`,
      generalQuestion2 :` Quels sont les rôles disponibles et quels sont leurs avantages ? `,
      generalAnswer2:`- **Candidat** : Postulez à des emplois et passez des tests (gratuit).
                 - **Recruteur** : Accès aux outils de création d'emploi et de test pour les recruteurs individuels.
                 - **Entreprise** : Fonctionnalités avancées pour les entreprises, y compris la gestion des équipes`,
      generalQuestion3 : `Comment puis-je choisir un rôle après m'être inscrit ? `,
     
      generalAnswer3:`Après avoir vérifié votre adresse e-mail, connectez-vous et choisissez votre rôle.
               Si vous choisissez 'Recruteur' ou 'Entreprise', un administrateur vous contactera pour vous guider et discuter des abonnements.`,
      subscription:"Subscription",
      subscriptionQuestion1: "Quels sont les abonnements disponibles pour les recruteurs et entreprises ?",
      subscriptionAnswer1:`Nous proposons des plans mensuels et annuels avec options de tests illimités, analyse de CV, et outils de pré-sélection.`,
      subscriptionQuestion2: "Comment fonctionne le processus d'abonnement ?",
      subscriptionAnswer2:`1. Choisissez le rôle 'Recruteur' ou 'Company'.\n
               2. Un administrateur vous contactera pour vous expliquer les options d'abonnement.\n
               3. Effectuez le paiement par virement.\n
               4. Votre compte sera activé sous 24h après la confirmation du paiement.`,
      payment:"Paiement",
      paymentQuestion1: "Quels modes de paiement acceptez-vous ?",
      paymentAnswer1:`Nous acceptons les cartes de crédit, PayPal, ainsi que les paiements via Stripe et Payme pour les paiements annuels.`,
      paymentQuestion2: "Recevrai-je une facture après le paiement ?",
      paymentAnswer2:`Oui, une facture est automatiquement générée et disponible dans votre tableau de bord après chaque paiement.`,
      paymentQuestion3: "Comment fonctionne le paiement via Stripe et Payme ?",
      paymentAnswer3:`Nous utilisons Stripe et Payme pour garantir des paiements sécurisés. Vous pouvez choisir l'un de ces modes de paiement lors de l'abonnement, et le processus de paiement est simple et rapide.`,
      support:"Support",
      supportQuestion1: "Comment contacter le support client ?",
      supportAnswer1:`Vous pouvez nous contacter via le formulaire sur la page 'Contactez-nous' ou envoyer un e-mail à contact@hirecue.com.`,
      supportQuestion2: "Y a-t-il une documentation pour les nouveaux utilisateurs ?",
      supportAnswer2:`Oui, des tutoriels vidéo et une documentation complète sont disponibles pour vous aider à utiliser la plateforme.`,
      additional : ` supplémentaire `,
      additionalQuestion1 : ` Que sont les candidats ? `,
      additionalAnswer1:`Les candidats sont des personnes qui postulent à des offres d'emploi et participent à des évaluations sur la plateforme.`,
      additionalQuestion2 : ` Comment Hirecue protège-t-il mes données ? `,
      additionalAnswer2:`Hirecue utilise des protocoles de cryptage et de sécurité pour s'assurer que vos données sont protégées à toutes les étapes de leur utilisation.`,
      additionalQuestion3 : ` Quelle est la différence entre une évaluation et un test ? `,
      additionalAnswer3:`Un assessment évalue les capacités et les compétences globales du candidat, tandis qu'un test est une évaluation spécifique d'une compétence particulière.`,
      additionalQuestion4 : ` Hirecue est-il facile à utiliser ? `,
      additionalAnswer4:`Oui, Hirecue est conçu avec une interface conviviale qui permet aux recruteurs et aux candidats de naviguer facilement.`,
      additionalQuestion5 : ` Quel type d'assistance offrez-vous ? `,
      additionalAnswer5:`Nous offrons un support par email, ainsi qu'un support téléphonique pour les abonnés premium. En outre, nous disposons d'une FAQ et d'une base de connaissances complètes`,
      additionalQuestion6 : "J'ai d'autres questions ",
      additionalAnswer6:"Si vous avez d'autres questions, n'hésitez pas à contacter notre équipe d'assistance via la page 'Contactez-nous' ou à envoyer un e-mail à contact@hirecue.com.",
      contacterNous:"Contactez-nous" ,
      envoiemail:"Envoyer un e-mail",
      choosePlan : "Choisissez votre plan ",
      choosePlanDesc : "Choisissez le plan qui correspond à vos besoins et commencez votre voyage dès aujourd'hui ! ",
      monthly:"Mensuel",
      annual:"Annuel",
      noJobsAvailable:"Aucune offre d'emploi disponible pour le moment.",
      errorFetchingJobs:"Erreur lors de la récupération des offres d'emploi.",
      loading:"Chargement en cours...",
      politiqueCookies: "Politique de Cookies",
      explanation: "Cette Politique de Cookies explique comment nous utilisons les cookies sur notre plateforme de recrutement. En utilisant notre site, vous acceptez l'utilisation de cookies conformément à cette politique. Si vous n'êtes pas d'accord avec l'utilisation des cookies, vous pouvez les désactiver ou modifier vos paramètres à tout moment.",
      whatIsACookie: "1. Qu'est-ce qu'un cookie ?",
      cookieDescription: "Un cookie est un petit fichier de données qui est stocké sur votre appareil (ordinateur, téléphone mobile, tablette) lorsque vous visitez un site web. Les cookies nous permettent de vous offrir une expérience personnalisée, de suivre votre navigation, et de nous aider à analyser l'utilisation de notre site.",
      whyCookies: "2. Pourquoi utilisons-nous des cookies ?",
      cookiePurpose: "Nous utilisons des cookies pour diverses raisons, notamment pour améliorer votre expérience sur notre plateforme de recrutement, personnaliser le contenu, et analyser l'interaction avec notre site. Voici les types de cookies que nous utilisons :",
      necessaryCookies: "Cookies nécessaires",
      necessaryCookiesDescription: "Ces cookies sont essentiels pour le bon fonctionnement de notre site. Ils permettent des fonctions de base telles que la gestion de votre session, l'accès à des zones sécurisées du site, et l'enregistrement de vos préférences de langue. Ces cookies ne peuvent pas être désactivés.",
      preferenceCookies: "Cookies de préférences",
      preferenceCookiesDescription: "Ces cookies mémorisent vos préférences, telles que votre langue ou la région que vous avez choisie, pour personnaliser votre expérience sur notre site.",
      performanceCookies: "Cookies de performance et d'analyse",
      performanceCookiesDescription: "Ces cookies nous permettent d'analyser l'utilisation du site afin de comprendre comment les visiteurs interagissent avec notre plateforme. Cela nous aide à améliorer l'efficacité et la performance de notre site.",
      advertisingCookies: "Cookies de publicité",
      advertisingCookiesDescription: "Ces cookies sont utilisés pour afficher des publicités pertinentes en fonction de vos intérêts. Ils peuvent également être utilisés pour limiter le nombre de fois où vous voyez une publicité et pour mesurer l'efficacité de la campagne publicitaire.",
      manageCookies: "3. Gestion des cookies",
      manageCookiesDescription: "Vous avez la possibilité de gérer vos préférences en matière de cookies. Vous pouvez désactiver les cookies à tout moment en accédant aux paramètres de votre navigateur. Veuillez noter que si vous désactivez les cookies, certaines fonctionnalités de notre site peuvent ne pas fonctionner correctement.",
      browserSettings: "Voici comment vous pouvez gérer les cookies sur les principaux navigateurs :",
      googleChrome: "Google Chrome : Allez dans 'Paramètres' > 'Confidentialité et sécurité' > 'Cookies et autres données de site'.",
      mozillaFirefox: "Mozilla Firefox : Allez dans 'Options' > 'Vie privée et sécurité' > 'Cookies et données de site'.",
      safari: "Safari : Allez dans 'Préférences' > 'Confidentialité' > 'Cookies et données de site web'.",
      microsoftEdge: "Microsoft Edge : Allez dans 'Paramètres' > 'Confidentialité, recherche et services' > 'Cookies'.",
      cookieConsent: "4. Consentement à l'utilisation des cookies",
      cookieConsentDescription: "En poursuivant votre navigation sur notre site, vous acceptez l'utilisation de cookies conformément à cette Politique de Cookies. Vous pouvez accepter ou refuser l'utilisation de cookies lorsque vous accédez à notre site pour la première fois, via la bannière de consentement des cookies.",
      cookiePolicyChanges: "5. Modifications de la Politique de Cookies",
      cookiePolicyChangesDescription: "Nous nous réservons le droit de modifier cette politique de cookies à tout moment. Toute modification sera publiée sur cette page. Nous vous encourageons à consulter régulièrement cette page pour prendre connaissance de toute mise à jour.",
      contact: "6. Contact",
      contactDescription: "Si vous avez des questions concernant notre Politique de Cookies, n'hésitez pas à nous contacter :",
      contactEmail: "Par e-mail :",
      contactPhone: "Par telephone :",
      contactAddress: "Par courrier",    
    
      lastUpdated: "Dernière mise à jour : ",
      politiqueConfidentialite: "Politique de Confidentialité",
      engagementConfidentialite: "Chez HireCue, nous nous engageons à protéger la confidentialité et la sécurité des informations personnelles de nos utilisateurs. Cette politique de confidentialité décrit comment nous collectons, utilisons et protégeons vos données dans le cadre de notre plateforme de recrutement.",
      collecteInformations: "1. Collecte des Informations",
      collecteDetails: "Nous collectons diverses informations vous concernant dans le cadre de l'utilisation de notre plateforme de recrutement, notamment :",
      informationInscription: "Les informations que vous fournissez lors de l'inscription en tant qu'entreprise, recruteur ou candidat.",
      informationCV: "Les informations contenues dans votre CV, y compris votre expérience professionnelle, vos compétences et vos qualifications.",
      informationTests: "Les données relatives à vos interactions avec les offres d'emploi, y compris les tests psychotechniques et les évaluations (techniques et QCM).",
      donneesNavigation: "Les données de navigation et d'utilisation de la plateforme, comme les pages visitées, les recherches effectuées, etc.",
      utilisationInformations: "2. Utilisation des Informations",
      utilisationDetails: "Les informations collectées sont utilisées pour les finalités suivantes :",
      gestionComptes: "Permettre la gestion des comptes utilisateur (candidats, recruteurs, entreprises).",
      associationCompetences: "Assurer la correspondance entre les compétences des candidats et les offres d'emploi en analysant les CV.",
      facilitationRecrutement: "Faciliter le processus de recrutement, y compris l'attribution de tests psychotechniques, des évaluations, et l'envoi d'invitations par e-mail aux candidats sélectionnés.",
      personnalisationExperience: "Personnaliser votre expérience sur la plateforme en fonction de vos préférences et actions passées.",
      ameliorationServices: "Améliorer nos services et la performance de la plateforme à travers l'analyse des données de navigation et d'utilisation.",
      protectionInformations: "3. Protection des Informations",
      protectionDetails: "Nous mettons en œuvre des mesures techniques et organisationnelles pour protéger vos données personnelles contre tout accès non autorisé, toute modification, divulgation ou destruction. Cependant, aucune méthode de transmission de données sur Internet n'est totalement sécurisée, et nous ne pouvons garantir la sécurité absolue des informations transmises.",
      partageInformations: "4. Partage des Informations",
      partageDetails: "Nous ne partageons vos informations personnelles qu'avec les parties suivantes :",
      recruteursPartage: "Les recruteurs et les entreprises inscrites sur notre plateforme qui ont besoin de vos informations pour évaluer votre candidature.",
      prestatairesServices: "Les prestataires de services tiers qui nous aident à exploiter la plateforme (par exemple, services de stockage de données, services de messagerie, outils d'analyse).",
      autoritesCompetentes: "Les autorités compétentes en cas d'obligation légale ou de demande légitime.",
      droitsUtilisateur: "5. Vos Droits",
      droitsDetails: "Conformément à la législation applicable sur la protection des données, vous disposez de plusieurs droits concernant vos données personnelles, notamment :",
      droitAcces: "Le droit d'accès à vos données personnelles.",
      droitRectification: "Le droit de rectifier ou de mettre à jour vos informations.",
      droitSuppression: "Le droit de supprimer vos informations ou de demander leur limitation de traitement.",
      droitOpposition: "Le droit de vous opposer au traitement de vos données dans certaines situations.",
      contactExerciceDroits: "Pour exercer ces droits, veuillez nous contacter à l'adresse suivante : contact@hirecue.com.",
      cookiesSuivi: "6. Cookies et Suivi",
      cookiesSuiviDetails: "Nous utilisons des cookies pour améliorer votre expérience sur notre plateforme. Ces cookies nous aident à analyser l'utilisation du site et à personnaliser le contenu. Pour plus d'informations sur la gestion des cookies, veuillez consulter notre",
      modificationsPolitique: "7. Modifications de la Politique de Confidentialité",
      modificationsDetails: "Nous nous réservons le droit de modifier cette politique de confidentialité à tout moment. Toute modification sera publiée sur cette page avec une nouvelle date de révision. Nous vous encourageons à consulter régulièrement cette politique pour être informé des mises à jour.",
      contact: "8. Contact",
      contactDetails: "Si vous avez des questions ou des préoccupations concernant cette politique de confidentialité, n'hésitez pas à nous contacter :",
      company:"Entreprise",
      droitreservee:"Tous droits réservés",
      getTouchdesc:"Commencez à travailler avec HireCue qui peut vous fournir tout ce dont vous avez besoin pour générer de la notoriété, générer du trafic, vous connecter",
      getTouch:"Entrez en contact",
      NousTitle:"Transformez votre recrutement et vos recherches d’emploi",
      NousDesc:"HireCue est une startup innovante dédiée à la révolution du recrutement et de la recherche d’emploi. Notre mission est de connecter les entreprises et les talents grâce à des outils intelligents, performants et centrés sur l’utilisateur.",
      
      organisationetService:"Organisation et services ",
      solRecTitle:"Solutions pour les recruteurs",
      solRecDesc:"Nous offrons une plateforme complète qui permet aux entreprises de gérer l’intégralité de leur processus de recrutement, de la présélection à l’embauche.",
      solRec1:"Tests de personnalité, psychotechniques et techniques",
      solRec1Desc:"Évaluez les candidats avec des tests variés adaptés à chaque poste.",
      solRec2:"Synthèse et recommandations automatiques",
      solRec2Desc:"Grâce à une IA avancée, nous garantissons le meilleur matching avec les postes à pourvoir.",
      solRec3:"Gestion centralisée des candidatures",
      solRec3Desc:"Un tableau de bord intuitif pour suivre et optimiser chaque étape du recrutement.",


      solCanTitle:"Solutions pour les candidats",
      solCanDesc:"Notre application aide les talents à trouver des opportunités parfaitement adaptées à leurs compétences et ambitions.",
      solCan1:"Matching personnalisé",
      solCan1Desc:"Des opportunités correspondant à vos compétences et attentes spécifiques.",
      solCan2:"Outils d’évaluation et de préparation",
      solCan2Desc:"Testez et développez vos compétences pour réussir dans votre domaine.",
      solCan3:"Création de CV optimisés",
      solCan3Desc:"Un générateur de CV moderne et attractif pour mettre en valeur votre profil.",

      ProPhare:"Nos Produits Phares",
      HireCueMatch:"Notre moteur IA pour un matching précis et efficace entre les candidats et les entreprises.",
      HireCueTests:"Une bibliothèque de tests spécialisés pour évaluer les aptitudes des candidats.",
      HireCueDashboard:"Un tableau de bord intuitif pour suivre et gérer chaque étape du recrutement.",
      HireCueBoost:"Un service d’accompagnement des candidats pour maximiser leurs chances de succès.",
      serviceFooter:"Chez HireCue, nous croyons en un recrutement plus intelligent, rapide et humain.",
      Country:"Pays ",
      Description:"Description",
      OfferedSalary:"Salaire offert",
      JobType:"Type d'emploi ",
      promotion40:"jusqu'à fin d'année pour les 100 premiers clients !",
      priceAfterPromotion: "Prix après réduction de 40%",
      liencopie: "Lien copié dans le presse-papier : ",
      joinWhatsAppGroup: "Rejoindre le groupe WhatsApp",
    }
  }
};
export default translations;
